import React from 'react'
import FieldInput from 'components/FieldInput'
import FieldWrapper from 'components/FieldWrapper'
import { isFieldDirty } from 'lib/formHelpers'
import { get } from 'lib/lodash'
import { getTransKey, langCode } from 'lib/i18n'
import { isBlank } from 'lib/helpers'
import { SHIPMENT_FIELDS } from 'config/constants'

const { CUSTOM_RETURN_REASON } = SHIPMENT_FIELDS

export default function CustomReturnReason(props) {
  const { errors, values, touched, t, shop } = props
  const value = get(values, CUSTOM_RETURN_REASON)
  const error = get(errors, CUSTOM_RETURN_REASON)

  const rawReturnReasonOptions = get(shop, `returnReasons.${langCode()}`, {})
  const repairShop = get(shop, 'repairShop', false)
  const gatekeeperEnabled = get(shop, 'gatekeeperEnabled', false)
  const returnReasonId = get(values, 'returnReasonId')

  const fieldInputProps = {
    ...props,
    id: CUSTOM_RETURN_REASON,
    name: CUSTOM_RETURN_REASON,
    label: t('details.form.customReturnReason.label'),
    isFieldDirty: isFieldDirty(touched, CUSTOM_RETURN_REASON),
    required: gatekeeperEnabled && isBlank(returnReasonId),
    value,
  }

  const currentReturnReason = rawReturnReasonOptions[+returnReasonId]
  if (repairShop) {
    fieldInputProps.label = t(
      'details.form.customReturnReason.repairShop.label',
    )
  }

  if (get(currentReturnReason, 'defect')) {
    fieldInputProps.label = t(
      'details.form.customReturnReason.repairShop.label',
    )
  }

  if (get(currentReturnReason, 'offersExchange')) {
    fieldInputProps.label = t(
      getTransKey('details.form.wantExchangeTo.label', shop),
    )
  }

  if (get(currentReturnReason, 'wrongItem')) {
    fieldInputProps.label = t('details.form.wrongItem.label')
  }

  return (
    <FieldWrapper
      errors={error}
      filled={value}
      classNames="input-field__wrapper__mobile-padding"
    >
      <FieldInput {...fieldInputProps} />
    </FieldWrapper>
  )
}
