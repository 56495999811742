import React, { useEffect, useState } from 'react'
import { Field } from 'formik'
import { withNamespaces } from 'react-i18next'
import { get, orderBy } from 'lib/lodash'
import cn from 'classnames'

function FieldSelect(props) {
  const {
    setFieldValue,
    name,
    onChange,
    value,
    error,
    options = [],
    optionsIndex = {},
    label,
    prompt,
    validate,
    optionsStyle = {},
  } = props
  const [open, setOpen] = useState(false)
  const [chosen, setChosen] = useState()

  useEffect(() => {
    if (value && value !== 'other' && !chosen) setChosen(true)
  }, [value])

  const toggle = () => setOpen(!open)

  const onOptionClick = (val) => {
    setChosen(true)
    setFieldValue(name, val)
    if (onChange) onChange(val)
    toggle()
  }

  const textValue = get(optionsIndex[value], 'text') || prompt
  const sortedOptions = orderBy(options, ['text'], ['asc'])

  return (
    <Field tabIndex={0} name={name} validate={validate}>
      {() => (
        <div
          className={`field-select--wrapper ${
            error
              ? 'field-select__wrapper-with-errors'
              : 'field-select__wrapper-is-valid'
          }`}
        >
          <label className="field-select--label control__label" htmlFor={name}>
            <div onClick={toggle} role="button" tabIndex={0}>
              {label}
            </div>
          </label>
          <div
            className="field-select--options--container"
            onBlur={() => setOpen(false)}
          >
            <div
              className={cn('field-select--options', {
                'field-select--options--chosen': chosen,
                'field-select--options--with-errors': error,
              })}
              style={optionsStyle}
              onClick={open ? undefined : toggle}
            >
              <div className="field-select--prompt">
                {open ? prompt : textValue}
              </div>
              {open &&
                sortedOptions.map((option, index) => (
                  <div
                    role="button"
                    tabIndex={index}
                    className={cn('field-select--option', {
                      'field-select--option--active': value === option.value,
                    })}
                    onClick={() => onOptionClick(option.value)}
                    selected={value === option.value}
                    key={option.value}
                  >
                    {option.text}
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </Field>
  )
}

export default withNamespaces('translation')(FieldSelect)
