import React, { useEffect } from 'react'
import qs from 'qs'
import { withNamespaces } from 'react-i18next'
import { get } from 'lib/lodash'
import CtaButton from 'components/CtaButton'
import i18n, { getTransKey, langCode } from 'lib/i18n'
import { SHIPMENT_FIELDS } from 'config/constants'
import { orderAction } from 'lib/actions'

import './index.scss'
import useHistory from 'hooks/useHistory'
import useOrder from 'hooks/useOrder'

const { SHIP_FROM_COUNTRY_CODE } = SHIPMENT_FIELDS

function CountriesPage(props) {
  const { noData, shop } = useOrder()

  const changeLanguage = () => {
    const parsed = qs.parse(get(location, 'search', '?').substring(1))
    const locale = get(parsed, 'locale')
    if (locale && langCode() !== locale) i18n.changeLanguage(locale)
  }

  useEffect(() => {
    if (noData) return

    changeLanguage()
    // eslint-disable-next-line
  }, [])

  const { goToReturnType } = useHistory()

  if (noData) return null

  const { location, t } = props
  const { countryCodesActual, withOtherCountry } = shop

  const onButtonClick = (value) => {
    orderAction.clearOrder()
    orderAction.setOrder({ [SHIP_FROM_COUNTRY_CODE]: value })
    goToReturnType()
  }

  const renderButtons = () => {
    const lengths = []
    const buttons = []

    countryCodesActual.forEach((code) => {
      const text = t(`countries.${code}`)
      lengths.push(text.length)
      buttons.push({ text, onClick: () => onButtonClick(code) })
    })

    // other button
    if (withOtherCountry) {
      buttons.push({
        text: t('countriesPage.other'),
        onClick: () => {
          orderAction.setOrder({ [SHIP_FROM_COUNTRY_CODE]: 'other' })
          goToReturnType()
        },
      })
    }

    const maxLength = Math.max(...lengths)
    buttons.map((el) => {
      const element = el
      element.buttonLength = maxLength + 4
      return element
    })
    return buttons.map((attrs) => <CtaButton {...attrs} key={attrs.text} />)
  }

  return (
    <div className="countries-page">
      <div className="countries-page__headers">
        <h2 style={{ marginBottom: '1rem' }}>
          {t(getTransKey('countriesPage.header', shop))}
        </h2>
        <h3 className="dimmed">{t('countriesPage.subHeader')}</h3>
      </div>
      <div className="countries-page__buttons">{renderButtons()}</div>
    </div>
  )
}

export default withNamespaces('translation')(CountriesPage)
