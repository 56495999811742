import { useSelector } from 'react-redux'
import { getShopAndOrder } from 'lib/selectors'
import orderHelper from 'lib/orderHelper'
import { isEmpty } from 'lib/lodash'

export default (props = {}) => {
  const { shop, order } = useSelector(getShopAndOrder)

  if (!shop || isEmpty(shop)) return { noData: true }
  if (!order || isEmpty(order)) return { noData: true }

  return orderHelper({ shop, order, ...props })
}
