import React, { useState, useEffect } from 'react'
import FieldSelect from 'components/FieldSelect'
import FieldWrapper from 'components/FieldWrapper'
import { isFieldDirty } from 'lib/formHelpers'
import { SHIPMENT_FIELDS } from 'config/constants'
import { isForeignCountry, isLocalCountry } from 'lib/other'
import useHistory from 'hooks/useHistory'
import useOrder from 'hooks/useOrder'
import { uniq, get, isEqual, isNil } from 'lib/lodash'
import { orderAction } from 'lib/actions'

const { SHIP_FROM_COUNTRY_CODE, NETWORK } = SHIPMENT_FIELDS

function ShipFromCountryCode(props) {
  const {
    noData,
    shop,
    order,
    isDropoff,
    isPickup,
    pricelists,
    localPricelists,
    dropoffLocalEnabled,
    pickupLocalEnabled,
  } = useOrder({
    values,
  })

  const shipFromCountryCode = get(order, SHIP_FROM_COUNTRY_CODE)
  const { goBack } = useHistory()
  const [countryCode, setCountryCode] = useState()

  useEffect(() => {
    if (noData) return

    if (isEqual(countryCode, shipFromCountryCode)) return
    if (isNil(countryCode)) {
      setCountryCode(shipFromCountryCode)
      return
    }

    const changedFromForeignToLocalCountry =
      isForeignCountry(countryCode) && isLocalCountry(shipFromCountryCode)

    if (changedFromForeignToLocalCountry) {
      const networksAvailable = uniq(pricelists.map((pl) => get(pl, NETWORK)))

      if (networksAvailable.length === 1) {
        // we pick that carrier and continue
        const network = networksAvailable[0]
        orderAction.setOrder({ [NETWORK]: network })
      }
      if (networksAvailable.length > 1) {
        // to pick a carrier related to the chosen origin
        goBack()
      }
    }
  }, [shipFromCountryCode])

  if (noData) return null

  const { errors, values, touched, t } = props
  const { countryOptionIndex: initialCountryOptionsIndex } = shop

  const value = get(values, SHIP_FROM_COUNTRY_CODE)
  const error = get(errors, SHIP_FROM_COUNTRY_CODE)

  const countryOptionsIndex = {}
  const network = get(order, NETWORK)

  pricelists
    .filter((pl) => network === pl.network)
    .forEach((pl) => {
      const option = { ...initialCountryOptionsIndex[pl.countryCode] }
      if (option) {
        option.text = t(`countries.${option.value}`)
        countryOptionsIndex[pl.countryCode] = option
      }
    })

  if (localPricelists.map((el) => el.countryCode).includes('nl'))
    countryOptionsIndex.nl = {
      text: t('countries.nl'),
      value: 'nl',
    }

  const optionOther = {
    text: t('countries.other'),
    value: 'other',
  }

  if (isLocalCountry(value)) {
    if (
      (isDropoff && dropoffLocalEnabled) ||
      (isPickup && pickupLocalEnabled)
    ) {
      countryOptionsIndex.other = optionOther
    }
  }

  const countryOptions = Object.values(countryOptionsIndex)
  const shipFromCountryCodeProps = {
    ...props,
    id: SHIP_FROM_COUNTRY_CODE,
    name: SHIP_FROM_COUNTRY_CODE,
    prompt: t('address.form.country.prompt'),
    label: t('address.form.country.label'),
    isFieldDirty: isFieldDirty(touched, SHIP_FROM_COUNTRY_CODE),
    optionsIndex: countryOptionsIndex,
    options: countryOptions,
    optionsStyle: { zIndex: 12 },
    value,
  }

  return (
    <FieldWrapper errors={error} filled={value} classNames="control-no-hr">
      <FieldSelect {...shipFromCountryCodeProps} />
    </FieldWrapper>
  )
}

export default ShipFromCountryCode
