import React from 'react'
import { isFieldDirty } from 'lib/formHelpers'
import CheckBoxElement from 'components/CheckBoxElement'
import InformationTooltip from 'components/InformationTooltip'
import { SHIPMENT_FIELDS } from 'config/constants'
import { get } from 'lib/lodash'
import { orderAction } from 'lib/actions'

const { INSURED } = SHIPMENT_FIELDS

export default function Insured(props) {
  const { touched, t, values, setFieldValue } = props

  const value = get(values, INSURED)
  const insuredProps = {
    ...props,
    name: INSURED,
    label: (
      <>
        {t('details.form.insurance.label')}{' '}
        <span style={{ fontWeight: '900' }}>
          {t('details.form.insurance.recommended')}
        </span>
        {' - 1,99'}
      </>
    ),
    isFieldDirty: isFieldDirty(touched, INSURED),
    value,
  }

  const tooltipBody = (
    <ul style={{ listStyle: 'disc', padding: '.6rem 1.5rem' }}>
      <li>{t('insurance.first')}</li>
      <li>{t('insurance.second')}</li>
      <li>{t('insurance.third')}</li>
    </ul>
  )

  return (
    <CheckBoxElement
      {...insuredProps}
      onChange={() => {
        orderAction.setOrder({ [INSURED]: !value })
        setFieldValue(INSURED, !value)
      }}
      tooltip={
        <InformationTooltip
          html={tooltipBody}
          iconStyle={{ marginLeft: '1rem' }}
        />
      }
    />
  )
}
