import { capitalize } from 'lib/helpers'
import { get, isNil, difference } from 'lib/lodash'
import shopSettings from 'config/shopSettings'
import { SHOP_FIELDS, SIZES } from 'config/constants'

const {
  GATEKEEPER_ENABLED,
  SHOP_OPTIONS,
  COUNTRIES,
  START_WITH_SHOPS_PAGE,
  NETWORKS,
  RETURN_TYPES,
  INITIAL_PRICELISTS,
} = SHOP_FIELDS

export const getPricelistCategoryName = ({ countryCode, returnType, size }) => {
  const parts = []
  parts.push(countryCode === 'nl' ? 'local' : 'international')
  parts.push(capitalize(returnType))
  parts.push(capitalize(size))
  return parts.join('')
}

export function transformShop({ data, t }) {
  const shop = data
  const initialPricelists = get(shop, INITIAL_PRICELISTS)
  const gatekeeperEnabled = get(shop, GATEKEEPER_ENABLED)
  const shopOptions = get(shop, SHOP_OPTIONS)
  const countries = get(shop, COUNTRIES)
  const networks = get(shop, NETWORKS)

  const pricelists = {
    localDropoffParcel: [],
    localDropoffLetterbox: [],
    localPickupParcel: [],
    internationalDropoffParcel: [],
    internationalPickupParcel: [],
  }

  const fieldName = ({ returnType, size }) =>
    `${returnType}${capitalize(size)}Price`

  const availableServices = {
    localDropoffParcel: false,
    localDropoffLetterbox: false,
    localPickupParcel: false,
    internationalDropoffParcel: false,
    internationalPickupParcel: false,
  }

  const returnTypes = get(shop, RETURN_TYPES)
  initialPricelists.forEach((pl) => {
    returnTypes.forEach((returnType) => {
      Object.values(SIZES).forEach((size) => {
        const price = pl[fieldName({ returnType, size })]
        if (isNil(price)) return
        if (!networks.includes(pl.network)) return

        const categoryName = getPricelistCategoryName({
          returnType,
          size,
          countryCode: pl.countryCode,
        })

        const category = pricelists[categoryName]
        pricelists[categoryName] = [...category, pl]
        availableServices[categoryName] = true
      })
    })
  })

  delete shop[INITIAL_PRICELISTS]

  const startWithShopsPage =
    get(shop, START_WITH_SHOPS_PAGE) && shopOptions.length > 0
  const onlyLocalShipments = Object.values(countries).length === 0

  let countryCodesAllowed = shopSettings('countryCodesAllowed', shop, [
    'nl',
    'be',
  ])

  // for CountriesPage
  let countryOptionIndex = {}
  Object.keys(countries).forEach((langCode) => {
    const langObj = { ...countries[langCode] }
    if (langObj) {
      langObj.text = t(`countries.${langCode}`)
      countryOptionIndex[langCode] = langObj
    }
  })

  countryOptionIndex = {
    nl: {
      text: t('countries.nl'),
      value: 'nl',
    },
    ...countryOptionIndex,
  }

  const countryCodes = Object.keys(countryOptionIndex)
  const countryCodesActual = []
  const notAllowedCodes = difference(countryCodesAllowed, countryCodes)
  countryCodesAllowed = difference(countryCodesAllowed, notAllowedCodes)
  countryCodesAllowed.forEach((code) => countryCodesActual.push(code))
  const withOtherCountry =
    difference(countryCodes, countryCodesAllowed).length > 0 &&
    !shopSettings('hideOtherCountry', shop, false)

  return {
    ...shop,
    initialPricelists,
    availableServices,
    gatekeeperEnabled,
    shopOptions,
    countries,
    startWithShopsPage,
    networks,
    pricelists,
    onlyLocalShipments,
    countryCodesAllowed,
    countryCodesActual,
    withOtherCountry,
    countryOptionIndex,
    customizations: {
      '--font-family-primary': "Playfair Display', serif",
    },
  }
}
