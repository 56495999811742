import React, { useState } from 'react'
import { get, times } from 'lib/lodash'
import { withNamespaces } from 'react-i18next'
import CheckBoxElement from 'components/CheckBoxElement'
import useOrder from 'hooks/useOrder'
import Name from './Name'
import Email from './Email'
import Phone from './Phone'
import OrderNumber from './OrderNumber'
import ReturnReason from './ReturnReason'
import CustomReturnReason from './CustomReturnReason'
import TermsAndConditions from './TermsAndConditions'
import Consent from './Consent'
import Insured from './Insured'
import Image from './Image'
import Item from './Item'
import './index.scss'

function Fields(props) {
  const values = get(props.form, 'values')
  const {
    shop,
    order,
    showName,
    showPhone,
    showAddressFields,
    showReturnReason,
    showCustomReturnReason,
    showReturnAllItems,
    showConsent,
    showInsured,
    showTermsAndConditions,
    showDescription,
    showDefectField,
  } = useOrder({
    values: { ...values, isEditing: props.isEditing, price: props.price },
  })

  const { form, addressFields, itemAmount, incrItemAmount, decrItemAmount, t } =
    props

  const defaultProps = {
    ...form,
    shop,
    order,
    type: 'input',
    classNames: 'input',
    hidden: false,
    showErrorCharacter: true,
    t,
  }

  const [returnAllItems, setReturnAllItems] = useState(false)
  const showItemsDescription = showDescription && !returnAllItems

  return (
    <>
      {showName && (
        <div className="field">
          <Name {...{ ...defaultProps, autoFocus: true }} />
        </div>
      )}

      <div className="field">
        <Email {...{ ...defaultProps, autoFocus: !showName }} />
      </div>

      {showAddressFields && addressFields}

      {showPhone && (
        <div className="field">
          <Phone {...defaultProps} />
        </div>
      )}

      <div className="field">
        <OrderNumber {...defaultProps} />
      </div>

      {showReturnReason && (
        <div className="field return-reason-field">
          <ReturnReason {...defaultProps} />
        </div>
      )}

      {showCustomReturnReason && (
        <div className="field custom-return-reason-field">
          <CustomReturnReason {...defaultProps} />
        </div>
      )}

      {showDefectField && (
        <div className="field image-field">
          <Image {...defaultProps} />
        </div>
      )}

      {showItemsDescription &&
        times(itemAmount, (index) => (
          <div className="field item-field" key={index}>
            <Item
              {...{
                ...defaultProps,
                index,
                itemAmount,
                incrItemAmount,
                decrItemAmount,
              }}
            />
          </div>
        ))}
      {showReturnAllItems && (
        <CheckBoxElement
          style={{ marginTop: '2rem' }}
          label={t('details.form.description.returnEntireOrder')}
          value={returnAllItems}
          onClick={() => setReturnAllItems(!returnAllItems)}
        />
      )}

      {showTermsAndConditions && (
        <div className="field" style={{ marginTop: '2rem' }}>
          <TermsAndConditions {...defaultProps} />
        </div>
      )}

      {showConsent && (
        <div className="field" style={{ marginTop: '0' }}>
          <Consent {...defaultProps} />
        </div>
      )}

      {showInsured && (
        <div className="field" style={{ marginTop: '3rem' }}>
          <Insured {...defaultProps} />
        </div>
      )}
    </>
  )
}

export default withNamespaces('translation')(Fields)
