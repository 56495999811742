import React from 'react'
import CtaButton from 'components/CtaButton'
import Arrow from 'components/Arrow'
import ConfirmationEmoji from 'components/ConfirmationEmoji'
import { isBlank } from 'lib/helpers'
import { formatAsDay } from 'lib/dateHelpers'
import i18n, { getTransKey } from 'lib/i18n'
import useOrder from 'hooks/useOrder'
import useHistory from 'hooks/useHistory'
import { DROPOFF_POINT_NAMES } from 'config/constants'

function SuccessOrder(props) {
  const {
    noData,
    shop,
    order,
    isDropoff,
    isPickup,
    isUpsPickup,
    isUpsDropoff,
    isDhl,
    isDhlPickup,
    isHomerr,
    isUps,
    isLocal,
  } = useOrder()
  const { goToEdit } = useHistory()

  if (noData) return null

  const { t, children, showEmoji = true } = props
  const locale = i18n.language.substring(0, 2)
  const {
    id: orderId,
    returnType,
    start,
    labelUrl,
    shipFromCountryCode,
    network,
  } = order

  const {
    shopUrl,
    paperlessOrigins = [],
    paperlessAvailable,
    multiDestinationsEnabled,
    dhlPaperlessEnabled,
  } = shop

  const labelCreated = !isBlank(labelUrl)
  const isDhlPaperless = isDhl && isDropoff && isLocal && dhlPaperlessEnabled
  const isUpsPaperless =
    isUpsDropoff &&
    paperlessOrigins.includes(shipFromCountryCode) &&
    paperlessAvailable
  const isPaperless = isUpsPaperless || isHomerr || isDhlPaperless

  const renderDropoffStatus = () => {
    if (!isDropoff) return null
    if (!labelCreated) return <h2>{t('confirmation.dropoff-header')}</h2>

    if (isHomerr)
      return (
        <>
          <h2 style={{ marginBottom: '1rem' }}>
            {' '}
            {t('confirmation.dropoff-qrcode-created-header')}
          </h2>
          <h3 className="dimmed">
            {' '}
            {t('confirmation.dropoff-qrcode-created-subheader', {
              pointName: 'Homerrpunt',
            })}
          </h3>
        </>
      )

    if (isPaperless)
      return (
        <>
          <h2 style={{ marginBottom: '1rem' }}>
            {' '}
            {t('confirmation.dropoff-barcode-created-header')}
          </h2>
          <h3 className="dimmed">
            {' '}
            {t('confirmation.dropoff-barcode-created-subheader', {
              pointName: DROPOFF_POINT_NAMES[network],
            })}
          </h3>
        </>
      )

    return <h2>{t('confirmation.dropoff-header-created')}</h2>
  }

  const barcodeLinkTranslation = t(
    getTransKey('confirmation.barcode-link', shop),
  )
  const labelLinkTranslation = t(getTransKey('confirmation.label-link', shop))

  let labelButtonText
  if (isDropoff) {
    labelButtonText = labelLinkTranslation
    if (isPaperless) labelButtonText = barcodeLinkTranslation
  }
  if (isUpsPickup) labelButtonText = labelLinkTranslation

  return (
    <>
      <div className="columns is-mobile is-multiline">
        <div className="column is-10 is-offset-1 confirmation__message">
          {showEmoji && <ConfirmationEmoji />}
          {isPickup && start && (
            <>
              <h2>{t('confirmation.header')}</h2>
              <h2>{`${t('confirmation.subtitle')} ${formatAsDay(
                start,
                locale,
              )}!`}</h2>
              {isDhl && (
                <h3 style={{ marginTop: '.4rem' }}>
                  {t('confirmation.courier-has-label')}
                </h3>
              )}
            </>
          )}

          {i18n.exists(`confirmation.shopLine.${shop.frontendAssetId}`) && (
            <h3>{t(`confirmation.shopLine.${shop.frontendAssetId}`)}</h3>
          )}

          {renderDropoffStatus()}
        </div>

        {children}
      </div>

      {false && isDhlPickup && !multiDestinationsEnabled && (
        <div className="columns is-mobile is-centered">
          <div className="column is-10 center-content">
            <button
              type="button"
              className="confirmation__edit--link"
              onClick={() => goToEdit(orderId)}
            >
              <span className="button__text">{t('confirmation.edit')}</span>
              <Arrow />
            </button>
          </div>
        </div>
      )}

      {returnType && (
        <div className="columns is-mobile is-centered">
          <div className="column is-10 center-content">
            {isDhlPickup && (
              <CtaButton
                text={t('confirmation.return')}
                arrow="right"
                onClick={() => {
                  window.location.href = shopUrl
                }}
              />
            )}

            {(isDropoff || isUpsPickup) && (
              <CtaButton
                submitting={!labelCreated}
                text={labelButtonText}
                arrow="right"
                onClick={() => window.open(labelUrl, '_blank')}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default SuccessOrder
