import React, { useEffect, useRef } from 'react'
import { Field } from 'formik'
import { withNamespaces } from 'react-i18next'
import checkIcon from 'assets/icons/check.svg'
import ReactSVG from 'react-svg'
import { get } from 'lib/lodash'

export const focusChangeByMaxLength = (e) => {
  if (e.target.value.length >= e.target.getAttribute('maxlength')) {
    const inputs = document.querySelectorAll('.input')
    let currentTabIndex = 0
    for (let i = 0; i < inputs.length; i += 1) {
      if (e.target === inputs[i]) {
        currentTabIndex = i
        break
      }
    }
    if (inputs[currentTabIndex + 1]) {
      inputs[currentTabIndex + 1].focus()
    }
  }
}

function FieldInput({
  id,
  name = '',
  placeholder,
  type,
  required,
  classNames,
  isSubmitting,
  errors,
  label,
  onBlur: customOnBlur,
  onChange: customOnChange,
  disabled,
  t,
  minLength,
  maxLength,
  inputmode,
  pattern,
  isFieldDirty,
  validate,
  autoFocus,
  value,
  items,
  grouped,
  showError = true,
}) {
  const labelRef = useRef(null)
  const wrapperRef = useRef(null)

  useEffect(() => {
    let labelHeight
    if (labelRef.current) {
      labelHeight = window
        .getComputedStyle(labelRef.current)
        .getPropertyValue('height')

      labelHeight = labelHeight.replace('px')
      labelHeight = parseInt(labelHeight, 10)
      labelHeight += 30
      labelHeight = `${labelHeight}px`
      if (wrapperRef.current) wrapperRef.current.style.marginTop = labelHeight
    }
  }, [wrapperRef, labelRef])

  let computedLabel = label
  const labelStyle = {}
  if (grouped && !label) {
    computedLabel = 'dummy'
    labelStyle.visibility = 'hidden'
  }

  const error = errors[name]
  let errorText = t(error)
  const key = get(error, 'key')
  if (key) errorText = t(error.key, error.values)

  return (
    <Field name={name} validate={validate}>
      {({ field }) => {
        const { onBlur: fieldBlur, onChange: fieldChange } = field

        const onBlur = (e) => {
          const one = customOnBlur === undefined || customOnBlur(e)
          const two = fieldBlur === undefined || fieldBlur(e)
          return one && two
        }
        const onChange = (e) => {
          const one = customOnChange === undefined || customOnChange(e)
          const two = fieldChange === undefined || fieldChange(e)
          return one && two
        }

        const hasErrors = errors[name] || (value === '' && required)

        let wrapperStyle
        let inputStyle
        if (items) {
          wrapperStyle = {
            display: 'flex',
          }
          inputStyle = {
            marginRight: '.5rem',
          }
        }

        return (
          <div
            ref={wrapperRef}
            className={`input-field__wrapper ${
              hasErrors
                ? 'input-field__wrapper-with-errors'
                : 'input-field__wrapper-is-valid'
            }`}
            style={wrapperStyle}
          >
            {isFieldDirty && !hasErrors && required ? (
              <span className="input-field__check">
                <ReactSVG src={checkIcon} />
              </span>
            ) : (
              ''
            )}
            <input
              id={id}
              className={classNames}
              type={type}
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              disabled={isSubmitting || disabled}
              required={required}
              minLength={minLength}
              maxLength={maxLength}
              inputMode={inputmode}
              pattern={pattern}
              autoFocus={autoFocus}
              style={inputStyle}
            />
            {items}
            <label
              htmlFor={name}
              className="control__label"
              ref={labelRef}
              style={labelStyle}
            >
              {computedLabel}
            </label>

            {errors[name] && showError ? <span>{errorText}</span> : null}
          </div>
        )
      }}
    </Field>
  )
}

export default withNamespaces('translation')(FieldInput)
