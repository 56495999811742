import { get } from 'lib/lodash'
import { langCode } from 'lib/i18n'

export function fetchReturnReasons(
  { id: shopId, returnReasons, showOtherReturnReason },
  t,
) {
  const rawReturnReasonOptions = get(returnReasons, langCode(), {})

  let otherOptionsText = t('details.form.returnReasonId.other')
  if (+shopId === +175)
    otherOptionsText = t('details.form.returnReasonId.otherAlt')
  const otherOption = {
    text: otherOptionsText,
    value: 0,
  }
  const returnReasonOptionsIndex = {
    0: otherOption,
    ...rawReturnReasonOptions,
  }
  const returnReasonOptions = Object.values(rawReturnReasonOptions)
  if (showOtherReturnReason) returnReasonOptions.push(otherOption)

  return [rawReturnReasonOptions, returnReasonOptionsIndex, returnReasonOptions]
}
