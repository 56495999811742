import { get, isEmpty, times } from 'lib/lodash'
import orderHelper from './orderHelper'
import { langCode } from './i18n'

export function getDetailsValidation(props) {
  const { shop, values, itemAmount = 1 } = props
  const {
    noData,
    isInternational,
    showName,
    showPhone,
    showDefectField,
    showConsent,
  } = orderHelper({
    shop,
    order: values,
  })
  if (noData) return null

  const {
    requireReturnReason,
    gatekeeperEnabled,
    requireImageOfDefect,
    showReturnReasons,
    showDescription,
    requireDescription,
  } = shop
  const returnReasons = get(shop, `returnReasons.${langCode()}`, {})
  const returnReasonId = get(values, 'returnReasonId')
  const currentReturnReason = returnReasons[+returnReasonId]

  const validateFields = []
  const requiredFields = []

  if (showName) {
    validateFields.push('name')
    requiredFields.push('name')
  }

  validateFields.push('email')
  requiredFields.push('email')

  if (showPhone) {
    validateFields.push('phone')
    requiredFields.push('phone')
  }

  if (showReturnReasons) {
    validateFields.push('returnReasonId')
    validateFields.push('customReturnReason')
  }

  if (requireReturnReason || gatekeeperEnabled) {
    if (isEmpty(returnReasons)) {
      requiredFields.push('customReturnReason')
    } else if (
      returnReasonId === 0 ||
      get(currentReturnReason, 'offersExchange') ||
      get(currentReturnReason, 'wrongItem') ||
      get(currentReturnReason, 'defect')
    ) {
      requiredFields.push('customReturnReason')
    } else requiredFields.push('returnReasonId')
  }

  if (showDefectField) validateFields.push('image')

  // TODO check for workshops
  if (showDefectField && (requireImageOfDefect || gatekeeperEnabled)) {
    requiredFields.push('image')
  }

  let validateItemAmount = 0
  if (showDescription) {
    validateFields.push('item')
    times(itemAmount, () => {
      validateItemAmount += 1
    })
  }

  let requiredItemAmount = 0
  if (requireDescription) {
    times(itemAmount, () => {
      requiredItemAmount += 1
    })
  }

  validateFields.push('termsAndConditions')
  requiredFields.push('termsAndConditions')

  if (showConsent) {
    validateFields.push('consent')
    requiredFields.push('consent')
  }

  console.log('DETAILS validateFields', validateFields)
  console.log('DETAILS requiredFields', requiredFields)

  return {
    validateFields,
    requiredFields,
    isInternational,
    validateItemAmount,
    requiredItemAmount,
  }
}

export function getAddressValidation(props) {
  const { shop, values } = props
  const { isLocal, isInternational, addressNeedsState, isPickup, isDropoff } =
    orderHelper({
      shop,
      order: values,
    })

  const { showPersonalFieldsForDropoff } = shop

  const validateFields = []
  const requiredFields = []

  if (isInternational) {
    validateFields.push('shipFromPostalCode')
    requiredFields.push('shipFromPostalCode')

    if (addressNeedsState) {
      validateFields.push('shipFromStateProvinceCode')
      requiredFields.push('shipFromStateProvinceCode')
    }

    validateFields.push('shipFromCountryCode')
    requiredFields.push('shipFromCountryCode')

    validateFields.push('shipFromCity')
    requiredFields.push('shipFromCity')

    validateFields.push('shipFromAddressLine')
    requiredFields.push('shipFromAddressLine')
  }

  if (isLocal && (isPickup || (isDropoff && showPersonalFieldsForDropoff))) {
    validateFields.push('postalCode')
    requiredFields.push('postalCode')

    validateFields.push('postalCodeEnd')
    requiredFields.push('postalCodeEnd')

    validateFields.push('houseNumber')
    requiredFields.push('houseNumber')

    validateFields.push('houseAddition')

    validateFields.push('street')
    requiredFields.push('street')

    validateFields.push('city')
    requiredFields.push('city')
  }

  console.log('ADDRESS validateFields', validateFields)
  console.log('ADDRESS requiredFields', requiredFields)

  return {
    validateFields,
    requiredFields,
    isInternational,
  }
}

export function getEditPickupValidation(props) {
  const { shop, values } = props
  const { showPhone, showName } = orderHelper({
    shop,
    order: values,
  })
  const { showDescription, requireDescription } = shop

  const validateFields = ['email']
  const requiredFields = ['email']

  if (showName) {
    validateFields.push('name')
    requiredFields.push('name')
  }

  if (showPhone) {
    validateFields.push('phone')
    requiredFields.push('phone')
  }

  if (showDescription) {
    validateFields.push('description')
  }

  if (requireDescription) {
    requiredFields.push('description')
  }

  // validateFields.push("comments");

  // console.log("EDIT validateFields", validateFields);
  // console.log("EDIT requiredFields", requiredFields);

  return {
    validateFields,
    requiredFields,
  }
}
