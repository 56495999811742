import { call, put } from 'redux-saga/effects'
import { implementPromiseAction } from '@adobe/redux-saga-promise'
import { get } from 'lib/lodash'
import api from 'features/rootApi'
import rootApiCatch from 'features/rootApiCatch'
import appActions from '../app/actions'
import shopActions from './actions'
import { transformShop } from './formatter'

export function* getShop(action) {
  yield call(implementPromiseAction, action, function* () {
    yield put(appActions.clearLoading())
    const { payload } = action
    const { t } = payload

    const requestPayload = { query: 'op:GetShop' }
    let response
    try {
      response = yield api(requestPayload)
    } catch (e) {
      yield put(appActions.setSettings('root'))
      yield put(rootApiCatch(requestPayload, e))
      throw e
    }

    const data = get(response, 'data.data.shop')
    const { frontendAssetId } = data
    yield put(appActions.setSettings(frontendAssetId))
    const shop = transformShop({ data, t })
    yield put(shopActions.getShopOk(shop))

    return data
  })
}
