import React from 'react'
import ReactSVG from 'react-svg'
import { getBranding } from 'lib/selectors'
import { useSelector } from 'react-redux'

function Logo() {
  const branding = useSelector(getBranding)
  const { logo, logoSvg, logoHeight } = branding || {}

  if (logoSvg) {
    return (
      <div className="store__logo">
        <ReactSVG src={logoSvg} style={{ maxHeight: `${logoHeight}px` }} />;
      </div>
    )
  }

  return (
    <div className="store__logo">
      <img
        className="store__logo-logo"
        style={{ maxHeight: `${logoHeight}px` }}
        src={logo}
        alt=""
      />
    </div>
  )
}

export default Logo
